var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Pane, Button, Row, Col, PaneFooter, ErrorModal, ConfirmationModal, } from "@folio/stripes/components";
import { useOkapiKy } from "@folio/stripes/core";
import { useQuery } from "react-query";
import { fetchOrganizationData, updateOrganization, } from "../../api/organizationService";
import RecekTextField from "../../components/RecekTextField";
function OrganizationEdit() {
    var _this = this;
    var history = useHistory();
    var id = useParams().id;
    var ky = useOkapiKy();
    var _a = useState(false), confirmModalOpen = _a[0], setConfirmModalOpen = _a[1];
    var _b = useState(""), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = useState(false), errorModalOpen = _c[0], setErrorModalOpen = _c[1];
    var _d = useState({}), errors = _d[0], setErrors = _d[1];
    var _e = useState({
        version: 0,
        name: "",
        source: "PUBLISHERS",
        sourceId: "",
        archived: false,
    }), formValues = _e[0], setFormValues = _e[1];
    var _f = useQuery(["organizations", id], function () { return fetchOrganizationData(id, ky); }, { enabled: !!id }), organizationData = _f.data, error = _f.error, isLoading = _f.isLoading;
    useEffect(function () {
        if (organizationData) {
            setFormValues(organizationData);
        }
    }, [organizationData]);
    var handleInputChange = function (e) {
        var value = e.target.value;
        setFormValues(function (prev) { return (__assign(__assign({}, prev), { name: value })); }); // Update only the name
    };
    var validateForm = function () {
        var newErrors = {};
        if (!formValues.name)
            newErrors.name = "Název je povinný.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    var handleSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var error_1, errorMessage_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    if (!validateForm()) {
                        // Stop if validation fails
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updateOrganization(ky, id, formValues)];
                case 2:
                    _b.sent();
                    history.goBack();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    if (((_a = error_1.response) === null || _a === void 0 ? void 0 : _a.status) === 409) {
                        setErrorMessage("Záznam není možné uložit. Záznam byl mezitím upraven. Prosím, načtěte jej a znovu upravte.");
                        setConfirmModalOpen(true);
                    }
                    else {
                        setErrorMessage("Došlo k chybě při aktualizaci organizace.");
                        setErrorModalOpen(true);
                    }
                    errorMessage_1 = "Do\u0161lo k chyb\u011B p\u0159i aktualizaci organizace: ".concat(error_1.message);
                    console.error("Error updating organization:", errorMessage_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // Display loading indicator while fetching data
    if (isLoading) {
        return React.createElement("div", null, "Loading...");
    }
    return (React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
        React.createElement(Pane, { defaultWidth: "fill", paneTitle: "Upravit organizaci", footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { type: "submit", buttonStyle: "primary", marginBottom0: true, onClick: handleSubmit }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); }, marginBottom0: true }, "Zru\u0161it") }) },
            React.createElement(ConfirmationModal, { open: confirmModalOpen, heading: "Chyba", message: errorMessage, onConfirm: function () {
                    return window.location.reload();
                }, onCancel: function () { return setConfirmModalOpen(false); }, confirmLabel: "Znovu načíst", cancelLabel: "Zavřít", buttonStyle: "primary" }),
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12 },
                        React.createElement(RecekTextField, { label: "Jm\u00E9no", value: formValues.name || "", onChange: handleInputChange, required: true })))))));
}
export default OrganizationEdit;
