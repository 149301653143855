import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import OnixCodetablesItemsList from "./OnixCodetablesItemsList";
// import OnixCodetablesItemsCreate from "./OnixCodetablesItemsCreate";
function OnixCodetablesItems() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: OnixCodetablesItemsList })));
}
export default OnixCodetablesItems;
