var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useMemo, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, MenuSection, Pane, AccordionSet, Accordion, Checkbox, ConfirmationModal, } from "@folio/stripes/components";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import AgencyPrefixesDetail from "./AgencyPrefixesDetail";
import { StripesConnectedSource } from "@folio/stripes/smart-components";
import { fetchAgencyPrefixes, deleteAgencyPrefix } from "../../api/agencyPrefixesService";
import { RegistrationAgency } from "../../types/RegistrationAgency";
import { FormattedMessage } from "react-intl";
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var AgencyPrefixesList = function () {
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var _a = useState(null), selectedAgencyPrefix = _a[0], setSelectedAgencyPrefix = _a[1];
    var _b = useState(true), filterPaneIsVisible = _b[0], setFilterPaneIsVisible = _b[1];
    var _c = useState({
        agencies: [],
    }), filters = _c[0], setFilters = _c[1];
    var _d = useState(false), showConfirmModal = _d[0], setShowConfirmModal = _d[1];
    var _e = useState(null), confirmAction = _e[0], setConfirmAction = _e[1];
    var _f = useState(""), modalMessage = _f[0], setModalMessage = _f[1];
    var openConfirmModal = function (prefix) {
        setConfirmAction(function () { return function () { return handleDeletePrefix(prefix.id); }; });
        setModalMessage("Opravdu chcete smazat agenturn\u00ED p\u0159edponu \"".concat(prefix.value, "\"?"));
        setShowConfirmModal(true);
    };
    var handleDeletePrefix = function (prefixId) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, deleteAgencyPrefix(ky, prefixId)];
                case 1:
                    _a.sent();
                    queryClient.invalidateQueries("agency-prefixes");
                    setShowConfirmModal(false);
                    setSelectedAgencyPrefix(null);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error deleting agency prefix:", error_1);
                    setModalMessage("Nepodařilo se smazat agenturní předponu.");
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var updateUrlWithFilters = function (filters) {
        var params = new URLSearchParams();
        filters.agencies.forEach(function (agency) { return params.append("agencies", agency); });
        history.push({ search: params.toString() });
    };
    var handleAgencyChange = function (agency) {
        setFilters(function (prevFilters) {
            var newAgencies = prevFilters.agencies.includes(agency)
                ? prevFilters.agencies.filter(function (a) { return a !== agency; })
                : __spreadArray(__spreadArray([], prevFilters.agencies, true), [agency], false);
            var updatedFilters = __assign(__assign({}, prevFilters), { agencies: newAgencies });
            updateUrlWithFilters(updatedFilters);
            return updatedFilters;
        });
    };
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var prefixId = queryParams.get("detail");
        if (prefixId) {
            fetchAgencyPrefixes(ky, filters).then(function (prefixes) {
                var prefix = prefixes.find(function (p) { return p.id === prefixId; });
                if (prefix) {
                    setSelectedAgencyPrefix(prefix);
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
        var newAgencies = queryParams.getAll("agencies");
        if (JSON.stringify(newAgencies) !== JSON.stringify(filters.agencies)) {
            setFilters(function (prevFilters) { return (__assign(__assign({}, prevFilters), { agencies: newAgencies })); });
        }
    }, [location.search, ky]);
    var isAnyFilterActive = function () { return filters.agencies.length > 0; };
    var _g = useQuery(["agency-prefixes", filters], function () { return fetchAgencyPrefixes(ky, { agencies: filters.agencies }); }, {
        enabled: isAnyFilterActive(),
        staleTime: 0,
        cacheTime: 0,
    }), _h = _g.data, agencyPrefixesData = _h === void 0 ? [] : _h, isLoading = _g.isLoading, isError = _g.isError, refetch = _g.refetch;
    var columns = useMemo(function () { return [
        {
            name: "Agentura",
            key: "agency",
            clickable: true,
            onClick: function (item) {
                var _a;
                var currentParams = new URLSearchParams(location.search);
                currentParams.delete("detail");
                currentParams.set("detail", (_a = item.id) !== null && _a !== void 0 ? _a : "");
                history.push({ search: currentParams.toString() });
                setSelectedAgencyPrefix(item);
            },
        },
        {
            name: "Hodnota",
            key: "value",
            cellRenderer: function (item) { var _a; return (_a = item.value) !== null && _a !== void 0 ? _a : "N/A"; },
        },
    ]; }, [location.search, history]);
    var source = new StripesConnectedSource({}, null);
    source.update = function () { };
    source.records = function () { return agencyPrefixesData; };
    source.resultCount = function () { return agencyPrefixesData.length; };
    source.totalCount = function () { return agencyPrefixesData.length; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isAnyFilterActive(); };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.fetchMore = function () { };
    source.fetchByBrowsePoint = function () { };
    source.fetchOffset = function () { };
    source.successfulMutations = function () { return []; };
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var handleClearFilters = function () {
        setFilters({ agencies: [] });
        history.push({ search: "" });
    };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
            showConfirmModal && (React.createElement(ConfirmationModal, { open: showConfirmModal, heading: "Potvr\u010Fte smaz\u00E1n\u00ED", message: modalMessage, onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!confirmAction) return [3 /*break*/, 2];
                                return [4 /*yield*/, confirmAction()];
                            case 1:
                                _a.sent();
                                refetch();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, onCancel: function () { return setShowConfirmModal(false); }, confirmLabel: "Smazat", cancelLabel: "Zru\u0161it", buttonStyle: "primary" })),
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace & filtrov\u00E1n\u00ED" },
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement(NavComponent, null),
                    React.createElement(Button, { buttonStyle: "none", id: "clickable-reset-all", disabled: !isAnyFilterActive(), marginBottom0: true, onClick: handleClearFilters }, "Resetovat v\u0161echno"),
                    React.createElement(AccordionSet, null,
                        React.createElement(Accordion, { label: "Typ registrace", separator: false }, Object.values(RegistrationAgency).map(function (agency) { return (React.createElement(Checkbox, { key: agency, label: agency, checked: filters.agencies.includes(agency), onChange: function () { return handleAgencyChange(agency); } })); })))))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? "fill" : "100%", paneTitle: React.createElement(FormattedMessage, { id: "ui-br-agency.agency-worker.agency-prefixes.title" }), actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                onToggle();
                                history.push("/bragency/agency-prefixes/create");
                            } }, "Nov\u00FD")));
                } },
                React.createElement(TableComponent, { data: agencyPrefixesData, columns: columns, pageSize: 30, searchTerm: "", hasDataFetched: isAnyFilterActive, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source })),
            selectedAgencyPrefix && (React.createElement(Pane, { defaultWidth: "35%", paneTitle: "Detail agenturn\u00ED p\u0159edpony", onClose: function () {
                    setSelectedAgencyPrefix(null);
                    var queryParams = new URLSearchParams(location.search);
                    queryParams.delete("detail");
                    history.replace({ search: queryParams.toString() });
                }, dismissible: true, actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return openConfirmModal(selectedAgencyPrefix); }, fullWidth: true }, "Smazat")));
                } },
                React.createElement(AgencyPrefixesDetail, { agencyPrefix: selectedAgencyPrefix }))))));
};
export default AgencyPrefixesList;
