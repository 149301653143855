import React from 'react';
import { ButtonGroup, Button } from '@folio/stripes/components';
import { useHistory, useLocation } from 'react-router-dom';
import { useOkapiKy } from '@folio/stripes/core';
import { hasAtLeastOneRoleAndDontHaveOther, useActiveRoles } from '../utils/permissionsUtils';
import { RoleEnum } from '../types/RoleEnum';
var NavigationComponent = function () {
    var history = useHistory();
    var location = useLocation();
    var activeRoles = useActiveRoles(useOkapiKy());
    // Define your routes
    var routes = [
        { path: '/brpublishers/ares-updates', label: 'Aresy', requiredRole: [RoleEnum.AGENCY_WORKER], notRequired: '' },
        { path: '/brpublishers/publisher-registrations', label: 'Registrace', requiredRole: [RoleEnum.AGENCY_WORKER], notRequired: '' },
        { path: '/brpublishers/publisher-updates', label: 'Aktualizace', requiredRole: [RoleEnum.AGENCY_WORKER], notRequired: '' },
        { path: '/brpublishers/publishers', label: 'Seznam', requiredRole: [RoleEnum.AGENCY_WORKER], notRequired: '' },
        { path: '/brpublishers/my-publisher', label: 'Muj nakladatel', requiredRole: [RoleEnum.PUBLISHER_ADMIN, RoleEnum.PUBLISHER_GUARANTOR], notRequired: RoleEnum.SUPERUSER },
        // TODO: My publisher
    ];
    // Determine the active route
    var currentPath = location.pathname;
    return (React.createElement(ButtonGroup, { style: { marginBottom: '1rem' }, fullWidth: true }, routes.map(function (route) {
        var isActive = currentPath.startsWith(route.path);
        return (React.createElement(React.Fragment, null, hasAtLeastOneRoleAndDontHaveOther(activeRoles, route.requiredRole, route.notRequired) &&
            React.createElement(Button, { key: route.path, onClick: function () { return history.push(route.path); }, style: {
                    backgroundColor: isActive ? '#1960a4' : '',
                    color: isActive ? '#fff' : '',
                    fontWeight: isActive ? 'bold' : '',
                } }, route.label)));
    })));
};
export default NavigationComponent;
