var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, AccordionSet, Accordion, TextField, IconButton, Row, Col, PaneFooter, ErrorModal, Modal, MenuSection, ConfirmationModal, } from "@folio/stripes/components";
import NavComponent from "../../components/NavComponent";
import { fetchCurrentUser, logout, updateUser, } from "../../api/usersService";
import RecekTextField from "../../components/RecekTextField";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var MyProfileDetail = function () {
    var _a;
    var history = useHistory();
    var ky = useOkapiKy();
    var activeRoles = useActiveRoles(ky);
    var _b = useState(false), confirmModalOpen = _b[0], setConfirmModalOpen = _b[1];
    var _c = useState(false), errorModalOpen = _c[0], setErrorModalOpen = _c[1];
    var _d = useState(""), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = useState(false), successModalOpen = _e[0], setSuccessModalOpen = _e[1];
    var _f = useState(""), successMessage = _f[0], setSuccessMessage = _f[1];
    var _g = useState(true), filterPaneIsVisible = _g[0], setFilterPaneIsVisible = _g[1];
    var _h = useState(true), expandAll = _h[0], setExpandAll = _h[1];
    var _j = useState({
        organization: true,
        profile: true,
    }), accordionStatus = _j[0], setAccordionStatus = _j[1];
    var _k = useState(null), userId = _k[0], setUserId = _k[1];
    var _l = useState({
        email: "",
        firstName: "",
        lastName: "",
        password: "",
    }), errors = _l[0], setErrors = _l[1];
    var _m = useState({
        version: 0,
        email: "",
        firstName: "",
        lastName: "",
        organization: "",
        roles: [],
    }), formValues = _m[0], setFormValues = _m[1];
    // Ref to prevent re-fetching `_self`
    var isFetchingUserId = useRef(false);
    // Fetch the user ID by calling `_self` API endpoint only once
    useEffect(function () {
        var fetchUserId = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response_1, error_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (isFetchingUserId.current)
                            return [2 /*return*/];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        isFetchingUserId.current = true;
                        return [4 /*yield*/, fetchCurrentUser(ky)];
                    case 2:
                        response_1 = _b.sent();
                        if ((_a = response_1 === null || response_1 === void 0 ? void 0 : response_1.user) === null || _a === void 0 ? void 0 : _a.id) {
                            setUserId(response_1 === null || response_1 === void 0 ? void 0 : response_1.user.id);
                            setFormValues(function (prevFormValues) {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                                return (__assign(__assign({}, prevFormValues), { organization: (_b = (_a = response_1 === null || response_1 === void 0 ? void 0 : response_1.activeOrganization) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "", roles: (_c = response_1 === null || response_1 === void 0 ? void 0 : response_1.activeRoles) !== null && _c !== void 0 ? _c : [], firstName: (_f = (_e = (_d = response_1 === null || response_1 === void 0 ? void 0 : response_1.user) === null || _d === void 0 ? void 0 : _d.personal) === null || _e === void 0 ? void 0 : _e.firstName) !== null && _f !== void 0 ? _f : "", lastName: (_j = (_h = (_g = response_1 === null || response_1 === void 0 ? void 0 : response_1.user) === null || _g === void 0 ? void 0 : _g.personal) === null || _h === void 0 ? void 0 : _h.lastName) !== null && _j !== void 0 ? _j : "", email: (_m = (_l = (_k = response_1 === null || response_1 === void 0 ? void 0 : response_1.user) === null || _k === void 0 ? void 0 : _k.personal) === null || _l === void 0 ? void 0 : _l.email) !== null && _m !== void 0 ? _m : "" }));
                            });
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _b.sent();
                        console.error("Error fetching user ID:", error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        isFetchingUserId.current = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        if (!userId) {
            fetchUserId();
        }
    }, []);
    var validateForm = function () {
        var newErrors = {
            email: "",
            firstName: "",
            lastName: "",
            password: "",
        };
        if (!formValues.email) {
            newErrors.email = "Email je povinný.";
        }
        else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
            newErrors.email = "Zadejte platný email.";
        }
        if (!formValues.firstName) {
            newErrors.firstName = "Jméno je povinné.";
        }
        if (!formValues.lastName) {
            newErrors.lastName = "Příjmení je povinné.";
        }
        setErrors(newErrors);
        return Object.values(newErrors).every(function (error) { return error === ""; });
    };
    var handleChange = function (e) {
        var _a = e.target, name = _a.name, type = _a.type;
        setFormValues(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[name] = e.target.value, _a)));
        });
    };
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var expandAllSections = function () {
        setAccordionStatus({
            organization: true,
            profile: true,
        });
        setExpandAll(true);
    };
    var collapseAllSections = function () {
        setAccordionStatus({
            organization: false,
            profile: false,
        });
        setExpandAll(false);
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            collapseAllSections();
        }
        else {
            expandAllSections();
        }
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    // Stop submission if there are errors
                    if (!validateForm()) {
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updateUser(ky, userId !== null && userId !== void 0 ? userId : "", {
                            version: formValues.version,
                            email: formValues.email,
                            firstName: formValues.firstName,
                            lastName: formValues.lastName,
                        })];
                case 2:
                    _b.sent();
                    setSuccessMessage("Uživatel byl úspěšně aktualizován.");
                    setSuccessModalOpen(true);
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _b.sent();
                    if (((_a = error_2.response) === null || _a === void 0 ? void 0 : _a.status) === 409) {
                        setErrorMessage("Záznam není možné uložit. Záznam byl mezitím upraven. Prosím, načtěte jej a znovu upravte.");
                        setConfirmModalOpen(true);
                    }
                    else {
                        setErrorMessage("Došlo k chybě při aktualizaci uživatele.");
                        setErrorModalOpen(true);
                    }
                    console.error("Error updating user:", error_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace", lastMenu: React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters" }) },
                React.createElement(NavComponent, null))),
            React.createElement(ConfirmationModal, { open: confirmModalOpen, heading: "Chyba", message: errorMessage, onConfirm: function () {
                    return window.location.reload();
                }, onCancel: function () { return setConfirmModalOpen(false); }, confirmLabel: "Znovu načíst", cancelLabel: "Zavřít", buttonStyle: "primary" }),
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement(Modal, { open: successModalOpen, label: "\u00DAsp\u011Bch", onClose: function () { return setSuccessModalOpen(false); }, dismissible: true },
                React.createElement("div", null, successMessage),
                React.createElement(Button, { onClick: function () { return setSuccessModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? "fill" : "100%", paneTitle: "M\u016Fj profil", firstMenu: !filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": "Show filters" })) : null, actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        hasRole(activeRoles, RoleEnum.AGENCY_WORKER) &&
                            React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                    onToggle();
                                    history.push("/brusers/my-profile/select-organization");
                                } }, "P\u0159ihl\u00E1sit se do jin\u00E9 Organizace"),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                onToggle();
                                history.push("/brusers/my-profile/password");
                            } }, "Zm\u011Bnit Heslo"),
                        hasRole(activeRoles, RoleEnum.SUPERUSER) &&
                            React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                    onToggle();
                                    history.push("/brusers/my-profile/import");
                                } }, "Import"),
                        hasRole(activeRoles, RoleEnum.AGENCY_WORKER) &&
                            React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                    onToggle();
                                    history.push("/brusers/audit-logs");
                                } }, "Auditn\u00ED Protokoly")));
                }, footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { type: "submit", buttonStyle: "primary", marginBottom0: true, onClick: handleSubmit }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "danger", onClick: function () { return logout(ky); } }, "Odhl\u00E1sit se") }) },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                        React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
                React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(AccordionSet, null,
                        React.createElement(Accordion, { label: "Organizace", open: accordionStatus.organization, onToggle: function () {
                                return setAccordionStatus(function (prev) { return (__assign(__assign({}, prev), { organization: !prev.organization })); });
                            } },
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextField, { label: "Organizace", value: formValues.organization, disabled: true })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextField, { label: "Role", value: ((_a = formValues.roles) === null || _a === void 0 ? void 0 : _a.length) > 0
                                            ? formValues.roles.join(", ")
                                            : "N/A", disabled: true })))),
                        React.createElement(Accordion, { label: "M\u016Fj u\u017Eivatel", open: accordionStatus.profile, onToggle: function () {
                                return setAccordionStatus(function (prev) { return (__assign(__assign({}, prev), { profile: !prev.profile })); });
                            } },
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(RecekTextField, { label: "Jm\u00E9no", name: "firstName", value: formValues.firstName, onChange: handleChange, required: true, error: errors.firstName })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(RecekTextField, { label: "P\u0159\u00EDjmen\u00ED", name: "lastName", value: formValues.lastName, onChange: handleChange, required: true, error: errors.lastName }))),
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(RecekTextField, { label: "Email", name: "email", value: formValues.email, onChange: handleChange, required: true, error: errors.email }))))))))));
};
export default MyProfileDetail;
