var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var getHeaders = function (additionalHeaders) {
    if (additionalHeaders === void 0) { additionalHeaders = {}; }
    return (__assign({ 'Content-Type': 'application/json', 'x-okapi-tenant': 'tritius', 'Cache-Control': 'no-cache, no-store, must-revalidate', Pragma: 'no-cache', Expires: '0' }, additionalHeaders));
};
