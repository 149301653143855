import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

// Routes
import {useOkapiKy} from '@folio/stripes/core';
import Settings from './settings';
import AresUpdates from './routes/ares-updates/AresUpdates.tsx';
import PublisherRegistrationsList from './routes/publisher-registrations/PublisherRegistrationsList.tsx';
import PublisherUpdatesList from './routes/publisher-updates/PublisherUpdatesList.tsx';
import Publishers from './routes/publishers/Publishers.tsx';
import MyPublisherRegistration from './routes/my-publisher/MyPublisherRegistration.tsx';
import {hasAtLeastOneRole, hasRole, useActiveRoles} from './utils/permissionsUtils';
import {RoleEnum} from './types/RoleEnum';
import MyPublisher from "./routes/my-publisher/MyPublisher";

const BrPublishers = (props) => {
  const okapiKy = useOkapiKy();
  const activeRoles = useActiveRoles(okapiKy);

  const {
    showSettings,
    match: {path},
  } = props;

  if (showSettings) {
    return <Settings {...props} />;
  }

  return (
    <Switch>
      <Route
        path={`${path}/ares-updates`}
        exact
        render={() => (
          hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
            <AresUpdates/>
          ) : (
            <Redirect to={`${path}`}/>
          )
        )}
      />
      <Route
        path={`${path}/publisher-registrations`}
        exact
        render={() => (
          hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
            <PublisherRegistrationsList/>
          ) : (
            <Redirect to={`${path}/publishers`}/>
          )
        )}
      />
      <Route
        path={`${path}/publisher-updates`}
        exact
        render={() => (
          hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
            <PublisherUpdatesList/>
          ) : (
            <Redirect to={`${path}/publishers`}/>
          )
        )}
      />
      <Route
        path={`${path}/publishers`}
        exact
        render={() => (
          hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
            <Publishers />
          ) : (
            <MyPublisher />
          )
        )}
      />
      <Route
        path={`${path}/my-publisher`}
        exact
        render={() => (
          hasAtLeastOneRole(activeRoles, [RoleEnum.PUBLISHER_ADMIN, RoleEnum.PUBLISHER_GUARANTOR]) ? (
            <MyPublisher />
          ) : (
            <Redirect to={`${path}/publishers`}/>
          )
        )}
      />
      <Route
        path={`${path}/my-publisher/*`}
        exact
        render={() => (
          hasAtLeastOneRole(activeRoles, [RoleEnum.PUBLISHER_ADMIN, RoleEnum.PUBLISHER_GUARANTOR]) ? (
            <MyPublisher />
          ) : (
            <Redirect to={`${path}/publishers`} />
          )
        )}
      />
      {/* Add a route for nested routes under /publishers */}
      <Route
        path={`${path}/publishers/*`}
        render={() => (
          hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
            <Publishers/>
          ) : (
            <Redirect to={`${path}/publishers`}/>
          )
        )}
      />
      {/* Default Redirect */}
      <Route
        render={() => (
          hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
            <Redirect to={`${path}/publishers`} />
          ) : (
            <Redirect to={`${path}/my-publisher`} />
          )
        )}
      />
    </Switch>
  );
};

export default BrPublishers;
