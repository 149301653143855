var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { Row, Col, Button, AccordionSet, Accordion, } from "@folio/stripes/components";
import { useHistory } from "react-router-dom";
var AgencyPrefixesDetail = function (_a) {
    var agencyPrefix = _a.agencyPrefix;
    var history = useHistory();
    var _b = useState({
        general: true,
        timestamps: true,
    }), accordionStatus = _b[0], setAccordionStatus = _b[1];
    var _c = useState(true), expandAll = _c[0], setExpandAll = _c[1];
    var formatDate = function (dateString) {
        // Return 'N/A' if the date is undefined or null
        if (!dateString)
            return "N/A";
        var date = new Date(dateString);
        var day = date.getDate().toString().padStart(2, "0");
        var month = (date.getMonth() + 1).toString().padStart(2, "0");
        var year = date.getFullYear();
        var hours = date.getHours().toString().padStart(2, "0");
        var minutes = date.getMinutes().toString().padStart(2, "0");
        return "".concat(day, ".").concat(month, ".").concat(year, " ").concat(hours, ":").concat(minutes);
    };
    var renderField = function (label, value) { return (React.createElement(Row, { style: { marginBottom: "25px" } },
        React.createElement(Col, { xs: 12 },
            React.createElement("div", { style: { marginBottom: "5px" } },
                React.createElement("strong", { style: { fontSize: "1.1em", paddingRight: "5px" } }, label)),
            React.createElement("div", null, value || "N/A")))); };
    var toggleAccordion = function (section) {
        setAccordionStatus(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[section] = !prevState[section], _a)));
        });
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            setAccordionStatus({
                general: false,
                timestamps: false,
            });
        }
        else {
            setAccordionStatus({
                general: true,
                timestamps: true,
            });
        }
        setExpandAll(!expandAll);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "Detail agenturn\u00ED p\u0159edpony"),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
        React.createElement(AccordionSet, null,
            React.createElement(Accordion, { id: "general-info", label: "Obecn\u00E9 informace", open: accordionStatus.general, onToggle: function () { return toggleAccordion("general"); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Agentura", agencyPrefix.agency))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Hodnota", agencyPrefix.value)))))));
};
export default AgencyPrefixesDetail;
