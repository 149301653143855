var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, QueryClient, QueryClientProvider, useQuery, } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, TextField, Row, Col, PaneFooter, AccordionSet, Accordion, Checkbox, Selection, ErrorModal, } from "@folio/stripes/components";
import { fetchOrganizations } from "../../api/organizationService";
import { assignUserToOrganization, createUser } from "../../api/usersService";
import RecekTextField from '../../components/RecekTextField';
var queryClient = new QueryClient({
    defaultOptions: {
        mutations: {
            onSuccess: function () {
                queryClient.invalidateQueries("users");
            },
        },
    },
});
// Define role options (these should ideally come from your backend or a constant file)
var availableRoles = [
    { code: "PublisherAdmin", label: "Správce nakladatele" },
    { code: "PublisherGuarantor", label: "Garant nakladatele" },
    { code: "AgencyWorker", label: "Pracovník agentury" },
];
var UserCreate = function () {
    var history = useHistory();
    var ky = useOkapiKy();
    var _a = useState(false), errorModalOpen = _a[0], setErrorModalOpen = _a[1];
    var _b = useState(""), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = useState({
        organization: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        sendPasswordEmail: false,
        selectedRoles: new Set(), // Use a Set to manage selected roles
    }), formValues = _c[0], setFormValues = _c[1];
    var _d = useState({
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        organizations: "",
    }), errors = _d[0], setErrors = _d[1];
    var _e = useState(true), expandAll = _e[0], setExpandAll = _e[1];
    var _f = useState({
        organization: true,
        profile: true,
        role: true,
    }), accordionStatus = _f[0], setAccordionStatus = _f[1];
    var mutation = useMutation(function (newUser) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ky.post("users", { json: newUser })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.json()];
            }
        });
    }); });
    var _g = useQuery(["organizations", {}], function () { return fetchOrganizations(ky, {}); }, { refetchOnWindowFocus: false }), organizations = _g.data, orgLoading = _g.isLoading;
    var handleChange = function (e) {
        var _a = e.target, name = _a.name, type = _a.type;
        if (type === "checkbox") {
            // Handle checkbox changes specifically
            var checked_1 = e.target.checked;
            // Check if it's the sendPasswordEmail checkbox
            if (name === "sendPasswordEmail") {
                console.log("true");
                setFormValues(function (prev) { return (__assign(__assign({}, prev), { sendPasswordEmail: checked_1 })); });
            }
            else {
                setFormValues(function (prev) {
                    var updatedRoles = new Set(prev.selectedRoles);
                    if (checked_1) {
                        updatedRoles.add(name);
                    }
                    else {
                        updatedRoles.delete(name);
                    }
                    return __assign(__assign({}, prev), { selectedRoles: updatedRoles });
                });
            }
        }
        else {
            setFormValues(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[name] = e.target.value, _a)));
            });
        }
    };
    var validateForm = function () {
        var newErrors = {
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            organizations: "", // Add organization to the errors object
        };
        if (!formValues.organization) {
            newErrors.organizations = "Organizace je povinná."; // Add an error message for organization
        }
        if (!formValues.email) {
            newErrors.email = "Email je povinný.";
        }
        else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
            newErrors.email = "Zadejte platný email.";
        }
        if (!formValues.firstName) {
            newErrors.firstName = "Jméno je povinné.";
        }
        if (!formValues.lastName) {
            newErrors.lastName = "Příjmení je povinné.";
        }
        if (!formValues.password) {
            newErrors.password = "Heslo je povinné.";
        }
        else if (formValues.password.length < 8) {
            newErrors.password = "Heslo musí mít alespoň 8 znaků.";
        }
        setErrors(newErrors);
        return Object.values(newErrors).every(function (error) { return error === ""; });
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var newUserPayload, newUser, rolesArray, error_1, errorMessage_1, response, emailExistsError;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!validateForm()) {
                        // Stop submission if there are errors
                        return [2 /*return*/];
                    }
                    newUserPayload = {
                        user: {
                            email: formValues.email,
                            firstName: formValues.firstName,
                            lastName: formValues.lastName,
                            shouldChangePassword: true,
                        },
                        password: formValues.password,
                        sendEmail: formValues.sendPasswordEmail,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 7, , 11]);
                    console.log(newUserPayload);
                    return [4 /*yield*/, createUser(ky, newUserPayload)];
                case 2:
                    newUser = _a.sent();
                    if (!(formValues.organization && formValues.selectedRoles.size > 0)) return [3 /*break*/, 4];
                    rolesArray = Array.from(formValues.selectedRoles);
                    return [4 /*yield*/, assignUserToOrganization(ky, newUser.id, formValues.organization, rolesArray)];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 4:
                    if (!formValues.organization) return [3 /*break*/, 6];
                    return [4 /*yield*/, assignUserToOrganization(ky, newUser.id, formValues.organization, [])];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6:
                    history.goBack();
                    return [3 /*break*/, 11];
                case 7:
                    error_1 = _a.sent();
                    console.error("Error creating user or assigning roles:", error_1);
                    errorMessage_1 = "Došlo k chybě při vytváření uživatele. Zkuste to prosím znovu.";
                    if (!error_1.response) return [3 /*break*/, 9];
                    return [4 /*yield*/, error_1.response.json()];
                case 8:
                    response = _a.sent();
                    // Check if the response contains 'errors' and it is an array
                    if (Array.isArray(response.errors)) {
                        // Log each error message received from the response
                        response.errors.forEach(function (err) {
                            console.log("Response error message:", err.message); // Log each error message
                        });
                        emailExistsError = response.errors.find(function (err) { return err.message.includes("Key (email)"); });
                        if (emailExistsError) {
                            errorMessage_1 =
                                "Došlo k chybě při vytváření uživatele. Tento email již existuje.";
                        }
                    }
                    else {
                        console.log("No error messages found in response:", response);
                    }
                    return [3 /*break*/, 10];
                case 9:
                    console.log("An unknown error occurred:", error_1);
                    _a.label = 10;
                case 10:
                    setErrorMessage(errorMessage_1);
                    setErrorModalOpen(true);
                    return [3 /*break*/, 11];
                case 11: return [2 /*return*/];
            }
        });
    }); };
    var expandAllSections = function () {
        setAccordionStatus({
            organization: true,
            profile: true,
            role: true,
        });
        setExpandAll(true);
    };
    var collapseAllSections = function () {
        setAccordionStatus({
            organization: false,
            profile: false,
            role: false,
        });
        setExpandAll(false);
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            collapseAllSections();
        }
        else {
            expandAllSections();
        }
    };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
            React.createElement(Pane, { defaultWidth: "fill", paneTitle: "Vytvo\u0159it u\u017Eivatele", footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { type: "submit", buttonStyle: "primary", marginBottom0: true, onClick: handleSubmit }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); }, marginBottom0: true }, "Zru\u0161it") }) },
                React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                    React.createElement("div", null, errorMessage),
                    React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                        React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
                React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(AccordionSet, null,
                        React.createElement(Accordion, { label: "Organizace", open: accordionStatus.organization, onToggle: function () {
                                return setAccordionStatus(__assign(__assign({}, accordionStatus), { organization: !accordionStatus.organization }));
                            } },
                            React.createElement(Selection, { label: "Organizace", name: "organization", value: formValues.organization, error: errors.organizations, disabled: orgLoading, onChange: function (value) {
                                    return setFormValues(function (prev) { return (__assign(__assign({}, prev), { organization: value })); });
                                }, dataOptions: organizations
                                    ? organizations.map(function (org) { return ({
                                        value: org.id,
                                        label: org.name,
                                    }); })
                                    : [] })),
                        React.createElement(Accordion, { label: "U\u017Eivatelsk\u00E9 informace", open: accordionStatus.profile, onToggle: function () {
                                return setAccordionStatus(__assign(__assign({}, accordionStatus), { profile: !accordionStatus.profile }));
                            } },
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(RecekTextField, { onChange: handleChange, label: "Jm\u00E9no", name: "firstName", value: formValues.firstName, required: true, error: errors.firstName })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(RecekTextField, { label: "P\u0159\u00EDjmen\u00ED", name: "lastName", value: formValues.lastName, onChange: handleChange, required: true, error: errors.lastName }))),
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(RecekTextField, { label: "Email", name: "email", value: formValues.email, onChange: handleChange, required: true, error: errors.email }))),
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextField, { label: "Heslo", name: "password", value: formValues.password, onChange: handleChange, required: true, error: errors.password }),
                                    React.createElement("p", null, "Heslo mus\u00ED m\u00EDt alespo\u0148 8 znak\u016F."))),
                            React.createElement(Checkbox, { label: "Odeslat heslo emailem", name: "sendPasswordEmail", checked: formValues.sendPasswordEmail, onChange: handleChange })),
                        React.createElement(Accordion, { label: "Role", open: accordionStatus.role, onToggle: function () {
                                return setAccordionStatus(__assign(__assign({}, accordionStatus), { role: !accordionStatus.role }));
                            } },
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 12 }, availableRoles.map(function (role) { return (React.createElement(Checkbox, { key: role.code, label: role.label, name: role.code, checked: formValues.selectedRoles.has(role.code), onChange: handleChange })); }))))))))));
};
export default UserCreate;
