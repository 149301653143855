var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { Row, Col, Checkbox, Button, AccordionSet, Accordion, } from "@folio/stripes/components";
import { useHistory } from "react-router-dom";
var PublisherDetail = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var publisher = _a.publisher;
    var history = useHistory();
    var _t = useState({
        general: true,
        address: true,
        contacts: true,
        guarantors: true,
        comments: true,
        alternativeNames: true,
        timestamps: true,
    }), accordionStatus = _t[0], setAccordionStatus = _t[1];
    var _u = useState(true), expandAll = _u[0], setExpandAll = _u[1];
    var formatDate = function (dateString) {
        if (!dateString)
            return "N/A"; // Return 'N/A' if the date is undefined or null
        var date = new Date(dateString);
        var day = date.getDate().toString().padStart(2, "0");
        var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        var year = date.getFullYear();
        var hours = date.getHours().toString().padStart(2, "0");
        var minutes = date.getMinutes().toString().padStart(2, "0");
        return "".concat(day, ".").concat(month, ".").concat(year, " ").concat(hours, ":").concat(minutes);
    };
    var contactTypeTranslations = {
        EMAIL: "E-mail",
        WEB: "Web",
        PHONE: "Telefon",
        DATA_BOX: "Datová schránka",
    };
    // Použití překladu při vykreslování
    var renderField = function (label, value) {
        var displayValue = value;
        if (label === "Typ" && typeof value === "string") {
            displayValue = contactTypeTranslations[value] || value;
        }
        return (React.createElement(Row, { style: { marginBottom: "25px" } },
            React.createElement(Col, { xs: 12 },
                React.createElement("div", { style: { marginBottom: "5px" } },
                    React.createElement("strong", { style: { fontSize: "1.1em", paddingRight: "5px" } }, label)),
                React.createElement("div", null, displayValue || "N/A"))));
    };
    var hasMailingAddress = ((_b = publisher.mailingAddress) === null || _b === void 0 ? void 0 : _b.street) ||
        ((_c = publisher.mailingAddress) === null || _c === void 0 ? void 0 : _c.postalCode) ||
        ((_d = publisher.mailingAddress) === null || _d === void 0 ? void 0 : _d.municipality);
    var hasContacts = publisher.contacts && publisher.contacts.length > 0;
    var hasGuarantors = publisher.guarantors && publisher.guarantors.length > 0;
    var hasAlternativeNames = publisher.alternativeNames && publisher.alternativeNames.length > 0;
    var toggleAccordion = function (section) {
        setAccordionStatus(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[section] = !prevState[section], _a)));
        });
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            setAccordionStatus({
                general: false,
                address: false,
                contacts: false,
                guarantors: false,
                comments: false,
                timestamps: false,
                alternativeNames: false,
            });
        }
        else {
            setAccordionStatus({
                general: true,
                address: true,
                contacts: true,
                guarantors: true,
                alternativeNames: true,
                comments: true,
                timestamps: true,
            });
        }
        setExpandAll(!expandAll);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null,
            publisher.name,
            ", ",
            publisher.nameSuffix),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
        React.createElement(AccordionSet, null,
            React.createElement(Accordion, { id: "general", label: "Obecn\u00E9 informace", open: accordionStatus.general, onToggle: function () { return toggleAccordion("general"); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("IČO", publisher.companyNumber)),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("DIČ", publisher.taxNumber))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Název", publisher.name)),
                    React.createElement(Col, { xs: 12, md: 6 }, publisher.legalForm === "NATURAL"
                        ? renderField("Datum narození", publisher.birthDate)
                        : "")),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Doplněk k názvu", publisher.nameSuffix)),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Kvalifikátor", publisher.qualifier))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Právní forma", publisher.legalForm === "LEGAL"
                        ? "Právnická osoba"
                        : "Fyzická osoba")))),
            React.createElement(Accordion, { id: "alternativeNames", label: "Alternativn\u00ED n\u00E1zvy", open: accordionStatus.alternativeNames, onToggle: function () { return toggleAccordion("alternativeNames"); } }, hasAlternativeNames ? ((_e = publisher.alternativeNames) === null || _e === void 0 ? void 0 : _e.map(function (altName, index) { return (React.createElement(Row, { key: index },
                React.createElement(Col, { xs: 12, md: 4 }, renderField("Název", altName.name)),
                React.createElement(Col, { xs: 12, md: 4 }, renderField("Doplněk k názvu", altName.nameSuffix)),
                React.createElement(Col, { xs: 12, md: 4 }, renderField("Kvalifikátor", altName.qualifier)),
                React.createElement(Col, { xs: 12, md: 4 }, renderField("Typ", altName.type === "PREVIOUS"
                    ? "Předchozí"
                    : altName.type === "IMPRINT"
                        ? "Imprint"
                        : altName.type === "ABBREVIATION"
                            ? "Zkratka"
                            : altName.type === "ORGANIZATION_UNIT"
                                ? "Organizační jednotka"
                                : altName.type === "FOREIGN_LANGUAGE"
                                    ? "Cizí jazyk"
                                    : "")),
                React.createElement(Col, { xs: 12, md: 4 }, renderField("Neveřejný", altName.nonPublic ? "Ano" : "Ne")))); })) : (React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement("div", null, "\u017D\u00E1dn\u00E9 alternativn\u00ED n\u00E1zvy k zobrazen\u00ED"))))),
            React.createElement(Accordion, { id: "address", label: "Adresa", open: accordionStatus.address, onToggle: function () { return toggleAccordion("address"); } },
                React.createElement("h3", null, "Adresa s\u00EDdla"),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 4 }, renderField("Ulice a číslo", (_f = publisher.mainAddress) === null || _f === void 0 ? void 0 : _f.street)),
                    React.createElement(Col, { xs: 12, md: 4 }, renderField("PSČ", (_g = publisher.mainAddress) === null || _g === void 0 ? void 0 : _g.postalCode)),
                    React.createElement(Col, { xs: 12, md: 4 }, renderField("Obec", (_h = publisher.mainAddress) === null || _h === void 0 ? void 0 : _h.municipality))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Sídelní kraj", (_k = (_j = publisher.mainAddress) === null || _j === void 0 ? void 0 : _j.region) === null || _k === void 0 ? void 0 : _k.name))),
                hasMailingAddress && (React.createElement(React.Fragment, null,
                    React.createElement("h3", null, "Doru\u010Dovac\u00ED adresa"),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 4 }, renderField("Ulice a číslo", (_l = publisher.mailingAddress) === null || _l === void 0 ? void 0 : _l.street)),
                        React.createElement(Col, { xs: 12, md: 4 }, renderField("PSČ", (_m = publisher.mailingAddress) === null || _m === void 0 ? void 0 : _m.postalCode)),
                        React.createElement(Col, { xs: 12, md: 4 }, renderField("Obec", (_o = publisher.mailingAddress) === null || _o === void 0 ? void 0 : _o.municipality))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 }, renderField("Sídelní kraj", (_q = (_p = publisher.mailingAddress) === null || _p === void 0 ? void 0 : _p.region) === null || _q === void 0 ? void 0 : _q.name)))))),
            React.createElement(Accordion, { id: "contacts", label: "Kontakty", open: accordionStatus.contacts, onToggle: function () { return toggleAccordion("contacts"); } }, hasContacts ? ((_r = publisher.contacts) === null || _r === void 0 ? void 0 : _r.map(function (contact, index) { return (React.createElement(Row, { key: index },
                React.createElement(Col, { xs: 12, md: 4 }, renderField("Typ", contact.type)),
                React.createElement(Col, { xs: 12, md: 4 }, renderField("Hodnota", contact.value)))); })) : (React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement("div", null, "\u017D\u00E1dn\u00E9 kontakty k zobrazen\u00ED"))))),
            React.createElement(Accordion, { id: "guarantors", label: "Garanti", open: accordionStatus.guarantors, onToggle: function () { return toggleAccordion("guarantors"); } }, hasGuarantors ? ((_s = publisher.guarantors) === null || _s === void 0 ? void 0 : _s.map(function (guarantor, index) {
                var _a;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Row, { key: index },
                        React.createElement(Col, { xs: 12, md: 4 }, renderField("Jméno", "".concat(guarantor.titlesBefore || "", " ").concat(guarantor.firstName, " ").concat(guarantor.lastName, " ").concat(guarantor.titlesAfter || "").trim())),
                        React.createElement(Col, { xs: 12, md: 4 }, renderField("E-mail", guarantor.email)),
                        React.createElement(Col, { xs: 12, md: 4 }, renderField("Agentury", guarantor.agencies))),
                    React.createElement("h3", null, "Kontakty"), (_a = guarantor.contacts) === null || _a === void 0 ? void 0 :
                    _a.map(function (contact, contactIndex) { return (React.createElement(Row, { key: "contact-".concat(index, "-").concat(contactIndex) },
                        React.createElement(Col, { xs: 12, md: 4 }, renderField("Typ", contact.type)),
                        React.createElement(Col, { xs: 12, md: 4 }, renderField("Hodnota", contact.value)))); })));
            })) : (React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement("div", null, "\u017D\u00E1dn\u00ED garanti k zobrazen\u00ED"))))),
            React.createElement(Accordion, { id: "comments", label: "Intern\u00ED pozn\u00E1mka", open: accordionStatus.comments, onToggle: function () { return toggleAccordion("comments"); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12 }, renderField("Interní poznámka", publisher.internalComment)))),
            React.createElement(Accordion, { id: "timestamps", label: "\u010Casy", open: accordionStatus.timestamps, onToggle: function () { return toggleAccordion("timestamps"); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Čas registrace", formatDate(publisher.createTime))),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Čas aktivace", formatDate(publisher.activationTime)))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Čas potvrzení údajů", formatDate(publisher.dataConfirmationTime))),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Čas uzavření", formatDate(publisher.closeTime)))))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement(Checkbox, { label: "Souhlas se zve\u0159ejn\u011Bn\u00EDm", checked: false, disabled: true })))));
};
export default PublisherDetail;
