var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useOkapiKy } from '@folio/stripes/core';
import { Button, Pane, Row, Col, PaneFooter, AccordionSet, Accordion, ErrorModal, TextField, Checkbox } from '@folio/stripes/components';
import { fetchUserById, resetPasswordAdmin } from '../../api/usersService'; // Import resetPassword
var UserReset = function () {
    var id = useParams().id;
    var history = useHistory();
    var ky = useOkapiKy();
    var _a = useQuery(['user', id], function () { return fetchUserById(ky, id); }, { enabled: !!id }), userData = _a.data, userError = _a.error, isUserLoading = _a.isLoading;
    var _b = useState(false), errorModalOpen = _b[0], setErrorModalOpen = _b[1];
    var _c = useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState({
        password: '',
        email: false,
    }), formValues = _d[0], setFormValues = _d[1];
    var _e = useState(true), expandAll = _e[0], setExpandAll = _e[1];
    var _f = useState({
        password: true,
        email: true,
    }), accordionStatus = _f[0], setAccordionStatus = _f[1];
    // Function to generate random password
    var generatePassword = function () {
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var password = '';
        for (var i = 0; i < 8; i++) {
            password += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setFormValues(function (prevValues) { return (__assign(__assign({}, prevValues), { password: password })); });
    };
    // Function to copy password to clipboard
    var copyToClipboard = function () {
        navigator.clipboard.writeText(formValues.password);
        alert('Heslo bylo zkopírováno do schránky!');
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    // Call the resetPassword function here
                    return [4 /*yield*/, resetPasswordAdmin(ky, id, formValues.password, formValues.email)];
                case 2:
                    // Call the resetPassword function here
                    _a.sent();
                    alert('Heslo bylo úspěšně resetováno!'); // Success message
                    // Redirect to user list or detail page
                    history.push("/brusers/users?search=Test&detail=".concat(id));
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error("Error resetting password:", error_1);
                    setErrorMessage("Došlo k chybě při resetování hesla.");
                    setErrorModalOpen(true);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            setAccordionStatus({ password: false, email: false });
            setExpandAll(false);
        }
        else {
            setAccordionStatus({ password: true, email: true });
            setExpandAll(true);
        }
    };
    useEffect(function () {
        // Generate password when component mounts
        generatePassword();
    }, []);
    return (React.createElement("div", { style: { display: 'flex', height: '93.5vh' } },
        React.createElement(Pane, { defaultWidth: "fill", paneTitle: "Resetovat heslo u\u017Eivatele ".concat(userData === null || userData === void 0 ? void 0 : userData.firstName, " ").concat(userData === null || userData === void 0 ? void 0 : userData.lastName), footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { type: "submit", buttonStyle: "primary", marginBottom0: true, onClick: handleSubmit }, "Potvrdit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); }, marginBottom0: true }, "Zru\u0161it") }) },
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, style: { display: 'flex', justifyContent: 'flex-end' } },
                    React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? 'Sbalit všechny' : 'Rozbalit všechny'))),
            React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(AccordionSet, null,
                    React.createElement(Accordion, { label: 'Heslo', open: accordionStatus.password, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { password: !accordionStatus.password })); } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 3 },
                                React.createElement(TextField, { label: "Vygenerovan\u00E9 heslo", value: formValues.password, readOnly: true })),
                            React.createElement(Col, { xs: 3 },
                                React.createElement(Button, { onClick: copyToClipboard, style: { marginTop: '25px' } }, "Kop\u00EDrovat")))),
                    React.createElement(Accordion, { label: 'Email', open: accordionStatus.email, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { email: !accordionStatus.email })); } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 3 },
                                React.createElement(Checkbox, { label: "Odeslat heslo emailem", name: "email", checked: formValues.email, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { email: e.target.checked })); } })))))))));
};
export default UserReset;
