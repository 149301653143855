import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import InternationalPrefixesList from "./InternationalPrefixesList";
import InternationalPrefixesCreate from "./InternationalPrefixesCreate";
function InternationalPrefixes() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: InternationalPrefixesList }),
        React.createElement(Route, { path: "".concat(cleanPath, "/create"), component: InternationalPrefixesCreate })));
}
export default InternationalPrefixes;
