var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Pane, Button, Row, Col, PaneFooter, Selection, } from "@folio/stripes/components";
import { useOkapiKy } from "@folio/stripes/core";
import { useMutation } from "react-query";
import { debounce } from "lodash";
import { fetchOrganizations } from "../../api/organizationService";
import { selectOrganization } from "../../api/usersService";
function SelectOrganization() {
    var _this = this;
    var history = useHistory();
    var id = useParams().id; // Extract the publisher ID from the URL
    var ky = useOkapiKy();
    // This would be your options for the select field
    var _a = useState(""), selectedOrg = _a[0], setSelectedOrg = _a[1]; // Holds selected user ID
    var _b = useState(false), orgLoading = _b[0], setOrgLoading = _b[1]; // Loading state for users
    var _c = useState(""), orgQuery = _c[0], setOrgQuery = _c[1];
    var _d = useState([]), organizations = _d[0], setOrganizations = _d[1];
    var _e = useState(""), name = _e[0], setName = _e[1];
    var _f = useState(""), organizationFilter = _f[0], setOrganizationFilter = _f[1];
    var debouncedSetUserQuery = useCallback(debounce(function (inputValue) {
        setOrgQuery(inputValue);
    }, 300), []);
    var handleOrgChange = function (e) {
        setSelectedOrg(e.target.value);
    };
    var handleOrganizationSelection = function (selectedValue) {
        setSelectedOrg(selectedValue);
        setOrganizationFilter(selectedValue);
    };
    var handleOrganizationInput = function (inputValue) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setOrgLoading(true);
                    setName(inputValue);
                    return [4 /*yield*/, debouncedSetUserQuery(inputValue)];
                case 1:
                    _a.sent(); // Use inputValue for debouncing
                    setOrgLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var fetchFilteredOrganizations = function () { return __awaiter(_this, void 0, void 0, function () {
            var fetchedUsers, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        if (!name) return [3 /*break*/, 2];
                        return [4 /*yield*/, fetchOrganizations(ky, { name: name })];
                    case 1:
                        fetchedUsers = _a.sent();
                        setOrganizations(fetchedUsers);
                        return [3 /*break*/, 3];
                    case 2:
                        setOrganizations([]); // Clear user list if no filters are set
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        console.error("Error fetching organizations:", error_1);
                        setOrganizations([]); // Clear user list in case of error
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        fetchFilteredOrganizations();
    }, [name]);
    var mutateSelectOrganization = useMutation(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, selectOrganization(ky, selectedOrg)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, {
        onSuccess: function () {
            history.push("/my-profile"); // Redirect or refresh logic after closing
        },
        onError: function (error) {
            console.error("Failed to select organization:", error);
            // Optionally handle error display here
        },
    }).mutateAsync;
    var handleSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    console.log("here");
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, mutateSelectOrganization()];
                case 2:
                    _a.sent();
                    console.log("Organization selected successfully");
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error("Error selecting organization:", error_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
        React.createElement(Pane, { defaultWidth: "fill", paneTitle: "Vybrat Organizaci", footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { type: "submit", buttonStyle: "primary", marginBottom0: true, onClick: handleSubmit }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); }, fullWidth: true }, "Zp\u011Bt") }) },
            React.createElement("form", { id: "organizationForm", onSubmit: handleSubmit },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12 },
                        React.createElement(Selection, { name: "orgSelection", value: selectedOrg, disabled: orgLoading, onChange: handleOrganizationSelection, asyncFilter: true, onFilter: handleOrganizationInput, dataOptions: organizations
                                ? organizations.map(function (org) { return ({
                                    value: org.id,
                                    label: org.name,
                                }); })
                                : [] })))))));
}
export default SelectOrganization;
