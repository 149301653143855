var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { Row, Col, Button, AccordionSet, Accordion, } from "@folio/stripes/components";
import { useHistory } from "react-router-dom";
var AuditLogDetail = function (_a) {
    var auditLog = _a.auditLog, setSelectedAuditLog = _a.setSelectedAuditLog;
    var history = useHistory();
    var _b = useState({
        action: true,
        id: true,
        time: true,
        user: true,
        data: true,
    }), accordionStatus = _b[0], setAccordionStatus = _b[1];
    var _c = useState(true), expandAll = _c[0], setExpandAll = _c[1];
    var formatDate = function (dateString) {
        if (!dateString)
            return "N/A"; // Return 'N/A' if the date is undefined or null
        var date = new Date(dateString);
        var day = date.getDate().toString().padStart(2, "0");
        var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        var year = date.getFullYear();
        var hours = date.getHours().toString().padStart(2, "0");
        var minutes = date.getMinutes().toString().padStart(2, "0");
        return "".concat(day, ".").concat(month, ".").concat(year, " ").concat(hours, ":").concat(minutes);
    };
    var renderField = function (label, value) { return (React.createElement(Row, { style: { marginBottom: "25px" } },
        React.createElement(Col, { xs: 12 },
            React.createElement("strong", null,
                label,
                ": "),
            React.createElement("div", null, value || "N/A")))); };
    // Helper function to format the JSON data
    var formatJsonData = function (jsonString) {
        if (!jsonString)
            return "N/A";
        try {
            var jsonData = JSON.parse(jsonString);
            // Return the formatted JSON string with indentation
            return JSON.stringify(jsonData, null, 2); // Indented for better readability
        }
        catch (error) {
            console.error("Failed to parse JSON:", error);
            return "Invalid JSON data";
        }
    };
    var toggleAccordion = function (section) {
        setAccordionStatus(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[section] = !prevState[section], _a)));
        });
    };
    var toggleExpandCollapseAll = function () {
        var newStatus = !expandAll;
        setAccordionStatus({
            action: newStatus,
            id: newStatus,
            time: newStatus,
            user: newStatus,
            data: newStatus,
        });
        setExpandAll(newStatus);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, auditLog.entityName),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
        React.createElement(AccordionSet, null,
            React.createElement(Accordion, { id: "action", label: "Akce", open: accordionStatus.action, onToggle: function () { return toggleAccordion("action"); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Akce", auditLog.entityAction)))),
            React.createElement(Accordion, { id: "id", label: "ID", open: accordionStatus.id, onToggle: function () { return toggleAccordion("id"); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Id", auditLog.entityId)))),
            React.createElement(Accordion, { id: "time", label: "\u010Cas", open: accordionStatus.time, onToggle: function () { return toggleAccordion("time"); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Čas", formatDate(auditLog.createTime))))),
            React.createElement(Accordion, { id: "user", label: "U\u017Eivatel", open: accordionStatus.user, onToggle: function () { return toggleAccordion("user"); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("E-mail", auditLog.user.email))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Jméno", auditLog.user.firstName))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField("Příjmení", auditLog.user.lastName)))),
            React.createElement(Accordion, { id: "data", label: "Data", open: accordionStatus.data, onToggle: function () { return toggleAccordion("data"); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement("strong", null, "Data:"),
                        React.createElement("pre", { style: { whiteSpace: "pre-wrap", wordBreak: "break-word" } }, formatJsonData(auditLog.data))))))));
};
export default AuditLogDetail;
