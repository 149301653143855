import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Redirect } from 'react-router';
import { useOkapiKy } from '@folio/stripes/core';
import { hasAtLeastOneRole, useActiveRoles } from '../../utils/permissionsUtils';
import { RoleEnum } from '../../types/RoleEnum';
import MyPublisherDetail from "./MyPublisherDetail";
import PublisherEdit from "../publishers/PublisherEdit";
function MyPublisher() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var activeRoles = useActiveRoles(useOkapiKy());
    var cleanPath = path.replace('/*', '');
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: MyPublisherDetail }),
        React.createElement(Route, { path: "".concat(cleanPath, "/edit/:id"), render: function () { return (hasAtLeastOneRole(activeRoles, [RoleEnum.PUBLISHER_ADMIN, RoleEnum.PUBLISHER_GUARANTOR]) ? (React.createElement(PublisherEdit, { status: "MY_PUBLISHER" })) : (React.createElement(Redirect, { to: "/publishers" }))); } })));
}
export default MyPublisher;
