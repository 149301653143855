import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AgencyPrefixesList from "./AgencyPrefixesList";
import AgencyPrefixesCreate from "./AgencyPrefixesCreate";
function AgencyPrefixes() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: AgencyPrefixesList }),
        React.createElement(Route, { path: "".concat(cleanPath, "/create"), component: AgencyPrefixesCreate })));
}
export default AgencyPrefixes;
