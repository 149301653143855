import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CollectionsList from "./CollectionsList";
import CollectionsCreate from "./CollectionsCreate";
function OnixCodetables() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: CollectionsList }),
        React.createElement(Route, { path: "".concat(cleanPath, "/create"), component: CollectionsCreate })));
}
export default OnixCodetables;
