import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

// Routes
import Settings from './settings';
import AgencyPrefixes from './routes/agency-worker/AgencyPrefixes.tsx';
import InternationalPrefixes from './routes/agency-worker/InternationalPrefixes.tsx';
import OnixCodetables from './routes/onix/OnixCodetables.tsx';
import OnixCodetablesItems from './routes/onix/OnixCodetablesItems.tsx';
import Collections from './routes/collections/Collections.tsx';

class BrAgency extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    showSettings: PropTypes.bool,
    stripes: PropTypes.shape({
      connect: PropTypes.func
    })
  };

  constructor(props) {
    super(props);

    this.connectedAgencyPrefixes = props.stripes.connect(AgencyPrefixes);
    this.connectedInternationalPrefixes = props.stripes.connect(InternationalPrefixes);
    this.connectedOnixCodetables = props.stripes.connect(OnixCodetables);
    this.connectedOnixCodetablesItems = props.stripes.connect(OnixCodetablesItems);
    this.connectedCollections = props.stripes.connect(Collections);
  }

  render() {
    const {
      showSettings,
      match: { path }
    } = this.props;

    if (showSettings) {
      return <Settings {...this.props} />;
    }

    return (
      <Switch>
        <Route
          path={`${path}/collections`}
          component={this.connectedCollections}
        />
        <Route
          path={`${path}/onix-codetables`}
          component={this.connectedOnixCodetables}
        />
        <Route
          path={`${path}/onix-codetables-items`}
          component={this.connectedOnixCodetablesItems}
        />
        <Route
          path={`${path}/international-prefixes`}
          component={this.connectedInternationalPrefixes}
        />
        <Route
          path={`${path}/agency-prefixes`}
          component={this.connectedAgencyPrefixes}
        />
        {/* Default Redirect */}
        <Route
          render={() => <Redirect to={`${path}/agency-prefixes`} />}
        />
      </Switch>
    );
  }
}

export default BrAgency;
