import React, { useMemo } from "react";
import { MultiColumnList, TextLink } from "@folio/stripes/components";
import { SearchAndSortNoResultsMessage as NoResultsMessage, } from "@folio/stripes/smart-components";
var statusTranslations = {
    REQUEST: "Žádost",
    REJECTED: "Odmítnuto",
    ACTIVE: "Aktivní",
    CLOSING: "V likvidaci",
    CLOSED: "Ukočený",
};
function TableComponent(_a) {
    var data = _a.data, columns = _a.columns, hasDataFetched = _a.hasDataFetched, searchTerm = _a.searchTerm, filterPaneIsVisible = _a.filterPaneIsVisible, toggleFilterPane = _a.toggleFilterPane, source = _a.source;
    var resultsStatusMessage = source ? (React.createElement("div", { "data-test-user-search-no-results-message": true },
        React.createElement(NoResultsMessage, { source: source, searchTerm: searchTerm, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane }))) : ("no source yet");
    var columnMapping = useMemo(function () {
        return columns.reduce(function (acc, col) {
            acc[col.key] = col.name;
            return acc;
        }, {});
    }, [columns]);
    var formatter = useMemo(function () {
        return columns.reduce(function (acc, col) {
            if (col.key === "status") {
                acc[col.key] = function (item) {
                    return statusTranslations[item[col.key]];
                }; // Translate status
            }
            else if (col.cellRenderer) {
                acc[col.key] = col.cellRenderer;
            }
            else if (col.clickable && col.onClick) {
                acc[col.key] = function (item) { return (React.createElement(TextLink, { onClick: function () { return col.onClick(item); } }, item[col.key])); };
            }
            else {
                acc[col.key] = function (item) { return item[col.key]; };
            }
            return acc;
        }, {});
    }, [columns]);
    var visibleColumns = useMemo(function () {
        return columns.map(function (col) { return col.key; });
    }, [columns]);
    return (React.createElement("div", null,
        React.createElement(MultiColumnList, { contentData: data, columnMapping: columnMapping, formatter: formatter, visibleColumns: visibleColumns, pagingType: "prev-next", isEmptyMessage: resultsStatusMessage, maxHeight: 680 })));
}
export default TableComponent;
