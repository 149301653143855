import React, { useState, useEffect } from "react";
import PublisherDetail from "../publishers/PublisherDetail";
import { useHistory } from "react-router-dom";
import { QueryClient, QueryClientProvider, } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, MenuSection, IconButton, PaneFooter, } from "@folio/stripes/components";
import { fetchPublisherData } from "../../api/publisherService";
;
import { hasAtLeastOneRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import { fetchCurrentUser } from "../../api/userService";
import NavComponent from "../../components/NavComponent";
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var MyPublisherDetail = function () {
    var history = useHistory();
    var ky = useOkapiKy();
    var activeRoles = useActiveRoles(ky);
    var _a = useState(null), selectedPublisher = _a[0], setSelectedPublisher = _a[1];
    var _b = useState(true), filterPaneIsVisible = _b[0], setFilterPaneIsVisible = _b[1];
    useEffect(function () {
        fetchCurrentUser(ky).then(function (user) {
            console.log(user);
            if (user.activeOrganization) {
                fetchPublisherData(user.activeOrganization.sourceId, ky).then(function (publisher) {
                    console.log(publisher);
                    setSelectedPublisher(publisher);
                });
            }
        });
    }, []);
    var handleConfirmData = function () {
        console.log('Potvrdit platnost údajů');
    };
    var handlePublisherCancel = function () {
        console.log('hlásit ukončení nakladatelské činnosti');
    };
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var footer = (React.createElement(PaneFooter, { renderStart: React.createElement(Button, { buttonStyle: "default", onClick: handleConfirmData }, "Potvrdit platnost \u00FAdaj\u016F"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: handlePublisherCancel }, "Ohl\u00E1sit ukon\u010Den\u00ED nakladatelsk\u00E9 \u010Dinnosti") }));
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace", lastMenu: filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters", style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement(NavComponent, null)))),
            React.createElement(Pane, { footer: footer, defaultWidth: filterPaneIsVisible ? "fill" : "100%", paneTitle: React.createElement(React.Fragment, null,
                    React.createElement("span", null, "Muj nakladatel")), actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" }, hasAtLeastOneRole(activeRoles, [RoleEnum.PUBLISHER_ADMIN, RoleEnum.PUBLISHER_GUARANTOR]) &&
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                return history.push("/brpublishers/my-publisher/edit/".concat(selectedPublisher.id));
                            } }, "Zm\u011Bnit")));
                }, firstMenu: !filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters", style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) }, selectedPublisher && React.createElement(PublisherDetail, { publisher: selectedPublisher })))));
};
export default MyPublisherDetail;
